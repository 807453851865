import netflixLogo from "../images/netflixLogo.png";
import disneyLogo from "../images/disneyLogo.png";
import fubotvLogo from "../images/fubotvLogo.png";
import youtubeLogo from "../images/youtubeLogo.png";
import hboLogo from "../images/hboLogo.png";
import huluLogo from "../images/huluLogo.png";
import f1tvLogo from "../images/f1Logo.png";
import primeLogo from "../images/primeLogo.png";
import peacockLogo from "../images/peacockLogo.png";
import paramountLogo from "../images/paramountLogo.png";
import youtubeTvLogo from "../images/youtubeTvLogo.png";
import appleLogo from "../images/appleLogo.png";

const Services = [
    {
        name: "Netflix",
        url: "https://netflix.com",
        logo: netflixLogo,
    },

    {
        name: "Youtube",
        url: "https://www.youtube.com",
        logo: youtubeLogo,
    },
    // {
    //     name: "YoutubeTV",
    //     url: "https://tv.youtube.com/",
    //     logo: youtubeTvLogo,
    // },
    // {
    //     name: "HBOMax",
    //     url: "https://max.com",
    //     logo: hboLogo,
    // },
    // {
    //     name: "Paramount",
    //     url: "https://www.paramountplus.com",
    //     logo: paramountLogo,
    // },

    {
        name: "Hulu",
        url: "https://secure.hulu.com/",
        logo: huluLogo,
    },
    {
        name: "Fubo TV",
        url: "https://www.fubo.tv/",
        logo: fubotvLogo,
    },
    {
        name: "AppleTV",
        url: "https://tv.apple.com/",
        logo: appleLogo,
    },
    {
        name: "F1",
        url: "https://f1tv.formula1.com/",
        logo: f1tvLogo,
    },
    {
        name: "Prime",
        url: "https://www.amazon.com/Amazon-Video/b/?ie=UTF8&node=2858778011&ref_=nav_cs_prime_video",
        logo: primeLogo,
    },
];

export default Services;
